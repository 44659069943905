
@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
  scroll-padding: 50px 0 0 0;
}

html body {
  background-color: whitesmoke;
  background-repeat: no-repeat;
  background-position: 45vw -5vh;
  background-size: auto 100vh;
  height: 100vh;
}

h1 {
  font-size: 60px !important;
}

h1.mt-5.header-text {
    text-align: center;
}

h4 {
  font-size: 35px !important;
  padding-bottom: 5px !important;
}

p {
  font-size: 17px !important;
}

h1, h6, h3, h4, h5 {
  color: #0661A8;
}

.header-text {
  color: #F2F2F2 !important;
  text-align: center;
}

.header-week {
  color: #F2F2F2;
  font-size: 15px;
  text-align: center;
}

.navbar {max-width: 100vw;}

@media only screen and (max-width: 600px) {
  .numb{margin-top: 50px;}
  .header-text {
      font-size: 3em!important;
  }
  html body {
    background-position: -70vw -5vh;
    background-size: auto 100vh;
  }
}

.card-title {
  color: white !important;
}

.card-dark {
  background-color: #142E3D !important;
}

.card-body {
  margin-bottom: -15px !important;
}

.price {
  color: #8dbee0 !important;
  font-size: 20px !important;
}
.price:hover {
  color: #b9dfff !important;
  font-size: 20px !important;
}
.activity-level {
  font-size: 25px !important;
}

.rates {
  padding-bottom: 15px !important;
}

@media only screen and (min-width: 600px) {
  .rates {
    padding-bottom: 30px !important;
  }
  .container-buttons {
    display: none;
  }

  .header-text {
    font-size: 80px !important;
  }
}

.rate-down {
  color: #ff4f4f !important;
}

.rate-moderate {
  color: #AE7129 !important;
}

.rate-up {
  color: #62ffa3 !important;
}

.activity-up {
  border-left: 8px solid #27AE60 !important;
}

.activity-down {
  border-left: 8px solid #E74C3C !important;
}

.activity-moderate {
  border-left: 8px solid #AE7129 !important;
}

.rates-sub-header, .disclaimer {
  color: #717A7F !important;
}

.rates-sub-sub-header {
  color: #717A7F !important;
  margin-top:-20px !important;
  font-size: 14px !important;
}

.footer-link-disclaimer {
  color: #0661A8 !important;
  text-decoration: underline !important;
}

.footer-link {
  color: #D9DCDF !important;
  text-decoration: underline !important;
}

.footer-link:hover, .footer-link-disclaimer:hover {
  color: #0661A8 !important;
}

.footer-copyright {
  color: #717A7F !important;
}

.table-header {
  background-color: #142E3D !important;
}

.table-segment {
  background-color: #222B31 !important;
  min-width: 125px !important;
}

.table-value {
  background-color: #1E2529 !important;
  text-align: right !important;
}

table {
  border-collapse: collapse !important;
  border-radius: 5px !important;
  overflow: hidden !important;
}

th, td {
  padding: 1em !important;
  background: #f8f8f8 !important;
  border: none !important;
}


.g {
  position: absolute !important;
}

.section-header {
  z-index: 100 !important;
}

.section-number {
  color: #20272D !important;
  font-size: 130px !important;
  position: relative !important;
  z-index: -100 !important;
  margin-bottom:-109px !important;
  left:0 !important;
}

.separator-gray {
  display: block !important;
  height: 4px !important;
  border-top: 4px solid #222B32 !important;
  margin-top: 15px !important;
  width: 80px !important;
}

.separator-blue {
  display: block !important;
  height: 2px !important;
  border-top: 2px solid #0661A8 !important;
  margin-bottom: 15px !important;
  width: 80px !important;
}


/* Segment Scroller */
.segment-scroller {
  overflow-x: scroll !important;
  overflow-y: hidden !important;
  white-space: nowrap !important;
  width: 100% !important;
  padding-bottom: 15px !important;
}

.btn-inactive {
  background-color: #1E2529 !important;
  color: #D9DCDF !important;
}

.btn-active {
  background-color: #053D6A !important;
  color: #D9DCDF !important;
}

.btn-active:hover, .btn-inactive:hover {
  color: #ffffff !important;
}

::-webkit-scrollbar
{
	height: 5px !important;
  width: 2px !important;
	background-color: #0B1821 !important;
}


::-webkit-scrollbar-track
{
	background-color: #1E2529 !important;
}

::-webkit-scrollbar-thumb
{
	background-color: #0661A8 !important;
    border-radius: 5px !important;
}

@media only screen and (min-width: 600px) {
  /* .waves { */
    /* margin-top: -100px; */
    /* margin-left: -90px; */
    /* position: absolute; */
    /* overflow: hidden; */
  /* } */
  .buttons {
    display: none !important;
  }
}

footer {
  background-image: url("./img/compass.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 300px;
}

.card-head {
  font-size: 12px !important;
  margin-bottom: 0px !important;
}

/* Custom Fonts */


h1, h4, .section-number {
  font-family: 'Open Sans';
}

h5, .price, .rates-sub-header, .table-header {
  font-family: 'Open Sans';
}

p, .disclaimer, .footer-link, .footer-link-disclaimer, .footer-copyright, .table-segment, .table-value, .header-week, .btn-inactive, .btn-active {
  font-family: 'Open Sans';
}

.display-linebreak {
  white-space: pre-line;
}

.fearnleys-logo {
  height: 25px;
  padding: 0px 0px 4px 5px;
}

.sharepoint {
  color: #ffffff;
  font-weight: bold;
  text-align: center;
}

.sharepoint-card {
  background-color: #3f51b5;
  padding: 10px;
  border-radius: 2%
}

#class > div > div > a.list-group-item.list-group-item-action.active {
  background: white!important;
  color: #000;
  border-color: lightgray;
}


.text-white {
  color: #ffffff;
}
.text-grey {
  color: #717A7F;
}

.middle-stuff {
  max-width: 1920px;
	margin: 0 auto !important;
	float: none !important;
}

.middle-stuff-fleet-search {
	margin-left: 1%;
}

.nav-tabs .nav-link {
  color: rgb(49, 49, 49)!important
}
.nav-tabs .nav-link.active {
  color: black!important
}

.dropdown-menu :active{
  background-color: grey!important;
}
.dropdown-menu .dropdown-item.active {
  background-color: gray;
}

.marginBox{
  margin-top: 20px;
}

.ignore-css h1{
  all: unset;
}
.ignore-css h2{
  all: unset;
}
.ignore-css h3{
  all: unset;
}
.ignore-css h4{
  all: unset;
}
.ignore-css h5{
  all: unset;
}
.ignore-css p{
  font-family: inherit;
}

.ql-toolbar {
  border-top: none!important;
  border-left: none!important;
  border-right: none!important;

}
.ql-container {
  border: none!important;
  height: 100px!important;
}
