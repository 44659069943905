.navBtn {
  height: 100%
}
#responsive-navbar-nav > div:nth-child(1) > div.nav-item.show.dropdown > div > a > a {
  color: #000;
}
td.tableHeaderDark {
  color: white!important;
  /* background-image: linear-gradient(to bottom, #3b454b, #142E3D)!important; */
  background-color: #142E3D!important;
  width: 160px;
  overflow-x: scroll;
}
.custom-tabs > a { color: gray;}
.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  color: black!important;
  font-weight: 500;
}
.nav-tabs .nav-link.active:hover, .nav-tabs .nav-item.show .nav-link {
  background-color: lightgray!important;
}
.custom-tabs > a:hover { background-color: lightgray; color: gray;}

.navbar-gradient {
  -webkit-box-shadow: 0 4px 3px -3px rgb(0, 0, 0);
    -moz-box-shadow: 0 4px 3px -3px rgb(0, 0, 0);
    box-shadow: 0 4px 3px -3px rgb(0, 0, 0);
  /* background-image: linear-gradient(to bottom, #3b454b, #142E3D)!important; */
  background-color: #142E3D!important;
  /* max-width: 1920px; applying max-width */
    /* margin: 0 auto; center */
}

#responsive-navbar-nav > div.ms-auto.navbar-nav > div > div > div > button {
  background-color: transparent;
  border-color: transparent;
}
.tableHeaderDark {
  color: white!important;
  /* background-image: linear-gradient(to bottom, #3b454b, #142E3D)!important; */
  background-color: #142E3D!important;
  width: 160px;
  overflow-x: scroll;
}
.bu-table {
  font-size: 12px;
}
.bunker-future-table {
  font-size: 12px;
}
.bunker-future-table thead th{
  color: #ffffff;
  /* background-image: linear-gradient(to bottom, #3b454b, #142E3D)!important; */
  background-color: #142E3D!important;
  padding-top: 15px;
  padding-bottom: 15px;
}
@media screen and (max-width: 600px) {
  .bunker-future-table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  .bunker-future-table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: .625em;
  }
  .bunker-future-table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 1em;
    text-align: right;
  }
  .bunker-future-table td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }
}
.fl-table {
  border-radius: 5px;
  font-size: 12px;
  font-weight: normal;
  border: none;
  border-collapse: collapse;
  width: 100%;
  max-width: 100%;
  white-space: nowrap;
  background-color: white;
}

.fl-table td,
.fl-table th {
  text-align: left;
  padding: 8px;
}

.fl-table td {
  border-right: 1px solid #f8f8f8;
  font-size: 12px;
  text-align: left;
}
.rate-table.fl-table td {
  font-size: 14px!important;
}
.rate-table.fl-table th {
  font-size: 14px!important;
}
.fl-table thead th {
  color: #ffffff;
  /* background-image: linear-gradient(to bottom, #3b454b, #142E3D)!important; */
  background-color: #142E3D!important;
  padding-top: 15px;
  padding-bottom: 15px;
}

.fl-table thead th:nth-child(odd) {
  color: #ffffff;
  /* background-image: linear-gradient(to bottom, #3b454b, #142E3D)!important; */
  background-color: #142E3D!important;
}

.fl-table tr:nth-child(even) {
  background: #F8F8F8;
}

#bigTable > tbody > tr:nth-child(even) td{
  background: #F8F8F8!important;
}
#bigTable > tbody > tr:nth-child(odd) td{
  background: #FFFFFF!important;
}
@media (max-width: 767px) {
  .fl-table {
      display: block;
      width: 100%;
  }
  .table-wrapper:before {
      content: "Scroll horizontally >";
      display: block;
      text-align: left;
      font-size: 11px;
      color: black;
      padding: 0 0 10px;
  }
  .fl-table thead,
  .fl-table tbody,
  .fl-table thead th {
      display: block;
  }
  .fl-table thead th:last-child {
      border-bottom: none;
  }
  .fl-table thead {
      float: left;
  }
  .fl-table tbody {
      width: auto;
      position: relative;
      overflow-x: auto;
  }
  .fl-table td,
  .fl-table th {
      padding: 20px .625em .625em .625em;
      height: 50px;
      vertical-align: middle;
      box-sizing: border-box;
      overflow-x: hidden;
      overflow-y: auto;
      width: auto;
      font-size: 13px;
      text-overflow: ellipsis;
  }
  .fl-table thead th {
      text-align: left;
      border-bottom: 1px solid #f7f7f9;
  }
  .fl-table tbody tr {
      display: table-cell;
  }
  .fl-table tbody tr:nth-child(odd) {
      background: none;
  }
  .fl-table tr:nth-child(even) {
      background: transparent;
  }
  .fl-table tr td:nth-child(odd) {
      background: #F8F8F8;
      border-right: 1px solid #E6E4E4;
  }
  .fl-table tr td:nth-child(even) {
      border-right: 1px solid #E6E4E4;
  }
  .fl-table tbody td {
      display: block;
      text-align: center;
  }
}

div.MuiInputBase-root.MuiFilledInput-root.navSegmentPicker, .navSegmentPicker select, .navSegmentPicker select:active {
  color: white;
  background-color: #142E3D;
}

.navSegmentPicker select option {
  color: black;
}

.headerSegmentPicker {
  display: inline-block;
  position: absolute;
  top: 2px;
  right: 2px;
}

.MuiDataGrid-main {
  color: black;
}

.MuiTablePagination-caption, .MuiDataGrid-selectedRowCount {
  color: white;
}

.MuiTablePagination-actions button {
  color: white;
}

.footer {
  background: #142E3D;
  color: white;
}

  .footer .links ul {
      list-style-type: none;
  }

  .footer .links li a {
      color: white;
      -webkit-transition: color .2s;
      transition: color .2s;
  }

      .footer .links li a:hover {
          text-decoration: none;
          color: #4180CB;
      }

  .footer .about-company i {
      font-size: 25px;
  }

  .footer .about-company a {
      color: white;
      -webkit-transition: color .2s;
      transition: color .2s;
  }

      .footer .about-company a:hover {
          color: #4180CB;
      }

  .footer .location i {
      font-size: 18px;
  }

  .footer .copyright p {
      border-top: 1px solid rgba(255, 255, 255, 0.1);
  }
  h4.fw-light {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 300!important;
    color: lightgray;
}


/* .tradingview-widget-container iframe {
  filter: hue-rotate(340deg) saturate(130%);
}*/

.news-header {
  padding: 10px;
  border-style: solid;
  border-color: #f5f5f5;
  border-top: hidden;
  border-left: hidden;
  border-right: hidden;
}
.news-element {
  padding: 10px;
  border-style: solid;
  border-color: #f5f5f5;
  border-top: hidden;
  border-left: hidden;
  border-right: hidden;
  border-width: thin;
}
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .twitter-container {
    display: none;
  }
  .desktop-export {
    display: none;
  }
  #responsive-navbar-nav {
    padding-left: 10px;
  }

  #responsive-navbar-nav > div:nth-child(1) > div.nav-item.show.dropdown > div {
    float: left;
  }
  #responsive-navbar-nav > div.ms-auto.navbar-nav > div > div > div {
    float: right;
    text-align: right;
  }
  #responsive-navbar-nav > div.ms-auto.navbar-nav > div > div > div > div {
    text-align: right;
  }
  .news-header {
    padding: 10px;
    border-style: solid;
    border-color: #f5f5f5;
    border-top: hidden;
    border-left: hidden;
    border-right: hidden;
  }
  .news-container {
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
  }
  .news-header::after {
    content: "scroll horizontally >>";
    color: white;
    font-size: x-small;
    display: flex;
    text-align: left;
  }
  .news-element {
    white-space: normal;
    width: 100%;
    display: inline-block;
    padding: 10px;
    border-style: solid;
    border-color: #f5f5f5;
    border-top: hidden;
    border-bottom: hidden;
    border-right: hidden;
    border-width: thin;
  }
}
.cargo-flow-content a {
  color: black;
  cursor:pointer;
}
.cargo-flow-content a:hover {
  color: grey;
  cursor:pointer;
}
.cargo-flow-content .nav-link.active {
  background-color: white!important;
  color: black!important;
  border: 1px solid;
  border-color: #000;
}

.scroll-table::-webkit-scrollbar {
  height: 0!important;
  width: 0!important;
  background-color: #0B1821 !important;
}

/* Remove duplicate toolbar (react 18 strict mode issue) */
/* https://github.com/zenoamaro/react-quill/issues/784  -> based on Tombars comment: */

.hemmelig > .quill > .ql-toolbar:first-child {
  display: none !important;
}

.quill h1 { 
  font-size: 2.5em !important;
}

.quill h2 { 
  font-size: 2em !important;
}

.quill h3 { 
  font-size: 1.5em !important;
}
